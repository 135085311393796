import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

// Components
import SEO from "../../../components/SEO"

// Helpers
import PREVIEW_QUERY from "../../../templates/blog-post/PreviewQuery.gql"
import { fireEvent, getLanguageFromURL, months } from "../../../utils/helpers"
import usePreviewClient from "../../../components/hooks/usePreviewClient"
import PreviewOverlay from "../../../components/PreviewOverlay"
import { TextBlockWrapper } from "../../../modules/TextBlock/TextBlock"
import Image from "../../../components/Image"
import { BlogImage } from "../../../templates/blog-post/blog-post"
import Chip from "../../../components/blog/Chip"

// markup
const PagePreview = ({ params }) => {
  let { search } = useLocation()
  const client = usePreviewClient()
  const [previewOverlay, setPreviewOverlay] = useState(false)
  const [content, setContent] = useState({})
  const { title, headline, date, image, richText, tags } = content
  let postDate = new Date(date)

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [content])

  useEffect(() => {
    if (client) {
      setPreviewOverlay(true)
      client
        .query({
          query: PREVIEW_QUERY,
          variables: { locale: getLanguageFromURL(search), slug: params.slug === "" ? "home" : params.slug },
        })
        .then(result => {
          console.log("Preview Result: ", result)
          setContent(result.data.entry)
          setPreviewOverlay(false)
          document.documentElement.setAttribute("lang", getLanguageFromURL(search))
        })
        .catch(err => {
          console.log("Error: ", err)
        })
    }
  }, [client])

  if (!client || !content.title) return <PreviewOverlay />

  return (
    <div className="relative">
      <SEO title="Preview" description="Preview" noindex={true} />
      {previewOverlay && <PreviewOverlay />}
      <div className="pt-28 px-5 sm:px-10 md:px-36 flex flex-col items-center">
        <div className="md:max-w-4xl w-full flex flex-col">
          <div className="flex mb-4 space-x-4">
            {tags.map(tag => (
              <Chip key={tag.title} text={tag.title} size="large" />
            ))}
          </div>
          <h4 className="text-blue font-extended mb-8">{title}</h4>
          <div className="text-gray-400">
            {postDate && <p className="text-n3">{months[postDate.getMonth()] + " " + postDate.getDate() + ", " + postDate.getFullYear()}</p>}
            {headline && <p className="text-n3">By {headline}</p>}
          </div>
        </div>
        <BlogImage className="relative overflow-hidden bg-green-background rounded-2xl my-8 sm:my-10 w-full">
          <Image
            alt="blog"
            src={image[0].url}
            className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full"
          />
        </BlogImage>
        <TextBlockWrapper className="max-w-4xl w-full my-6 sm:my-10 space-y-8" dangerouslySetInnerHTML={{ __html: richText }} textTheme="dark" />
      </div>
    </div>
  )
}

PagePreview.propTypes = {
  params: PropTypes.object,
}

export default PagePreview
